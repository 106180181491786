$(document).ready(function() {
    var $scrollSpyOffset = [];
    var $headerHeight = $('.header').innerHeight();
    var $navHeight = $('.header--nav').outerHeight();
    var $navLinks = $('.header--nav--links--li--link[data-target]');
    if ($navLinks.length) {
        getScrollSpyOffset();
        $navLinks.on('click', function (e) {
           var $target = $(this).data('target');
           var $element = $('section[data-target="' + $target + '"]');
            scrollTo($element);
        });
    }

    $(window).scroll(function () {
        scrollHeader();
    });

    $(window).on('load', function () {
        scrollHeader();
    });

    $pushInformations = $('.push--presentation--informations');
    if ($pushInformations.length) {
        $('.push--presentation--informations--cta--link', $pushInformations).on('click', function() {
            var $target = $(this).data('target');
            var $element = $('section[data-target="' + $target + '"]');
            scrollTo($element);
        });
    }

    var $productImagesSlide = $('.push--product--images--slide');
    if ($productImagesSlide.length) {
        initProductImagesSlide();
    }

    var $productAttributes = $('.push--product--attributes');
    if ($productAttributes.length) {
        $refresh_url = $productAttributes.data('refresh');
        $('.push--product--attributes--item--input--radio', $productAttributes).on('change', function (e) {
            var $data = $('.push--product--inner').serialize();
            $.post({
                url: $refresh_url,
                data: $data,
                success: function (response) {
                    $product = JSON.parse(response);
                    $('.push--product--price').text($product.price);
                    if ($product.images.length) {
                        $productImagesSlide.slick('unslick');
                        $('.push--product--images--slide').empty();
                        for (var $i = 0; $i < $product.images.length; $i++) {
                            var $image = $product.images[$i];
                            var $class = '';
                            if ($image.cover == '1') {
                                $class = 'push--product--images--slide--image--cover';
                            }
                            $('<div class="push--product--images--slide--image ' + $class + '" data-id="' + $i + '"><img data-image-medium-src="' + $image.medium + '" data-image-large-src="' + $image.large + '" data-image-home-src="' + $image.home + '" src="' + $image.large + '" width="' + $image.size.width + '" height="' + $image.size.height + '" alt="' + $image.legend + '" title="' + $image.legend + '" /></div>').appendTo($('.push--product--images--slide'));
                        };
                        initProductImagesSlide();
                    }
                    if ($product.description) {
                        $('.push--product--text--description').html($product.description);
                    }
                }
            })
        });
    }

    function initProductImagesSlide() {
        var $idCover = 0;
        var $productImageCover = $('.push--product--images--slide--image--cover');
        if ($productImageCover.length) {
            $idCover = $productImageCover.data('id');
        }
        $productImagesSlide.slick({
            accessibility: false,
            arrows: false,
            appendDots: '.push--product--images--slide--dots',
            autoplay: false,
            centerMode: false,
            centerPadding: '0',
            cssEase: 'ease',
            dots: true,
            edgeFriction: 0,
            fade: true,
            draggable: true,
            focusOnSelect: true,
            infinite: true,
            initialSlide: $idCover,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 1000,
            swipe: true,
            touchMove: true,
        });
    }

    $associatedProducts = $('.cart--associated-products');
    if ($associatedProducts.length) {
        $associatedProductsSlide = $('.cart--associated-products--inner', $associatedProducts);
        $associatedProductsSlide.slick({
            accessibility: false,
            arrows: true,
            autoplay: false,
            centerMode: true,
            centerPadding: '0',
            cssEase: 'ease',
            dots: false,
            edgeFriction: 0,
            fade: false,
            draggable: true,
            focusOnSelect: true,
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 4,
            speed: 1000,
            swipe: true,
            touchMove: true,
            prevArrow: '<button type="button" class="cart--associated-products--slick--arrow cart--associated-products--slick--arrow--prev"><img src="/themes/regofit/assets/dest/img/picto-nav.svg" /></button>',
            nextArrow: '<button type="button" class="cart--associated-products--slick--arrow cart--associated-products--slick--arrow--next"><img src="/themes/regofit/assets/dest/img/picto-nav.svg" /></button>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ],
        });
    }
    
    var $accountNavigation = $('.account--forms--navigation');
    if ($accountNavigation.length) {
        $('.account--forms--navigation--link', $accountNavigation).on('click', function (e) {
            $('.account--forms--navigation--link', $accountNavigation).toggleClass('account--forms--navigation--link--active');
            $('.account--forms').toggleClass('account--forms--active account--forms--unactive');
        });
    }

    function scrollTo($element) {
        var offsetHeight = document.body.clientWidth > 768
            ? $('.header--nav').outerHeight()
            : $('.header--nav').outerHeight() + $('.header--logo').outerHeight();

        $('html, body').animate({
            scrollTop: $element.offset().top - offsetHeight,
        }, 500);
    }

    function scrollHeader () {
        var $scroll = $(window).scrollTop();
        var $height = $headerHeight - $navHeight;

        if ($scroll >= $height) {
            $('.header').addClass('header--scrolled');
        } else {
            $('.header').removeClass('header--scrolled');
        }

        if ($navLinks.length) {
            $scroll = $scroll + $navHeight;
            $navLinks.each(function (index, element) {
                var $target = $(this).data('target');
                if ($scroll >= $scrollSpyOffset[$target]['offset'] && $scroll < $scrollSpyOffset[$target]['height']) {
                    $('.header--nav--links--li').removeClass('header--nav--links--li--active');
                    $(this).addClass('header--nav--links--li--active');
                } else {
                    $(this).removeClass('header--nav--links--li--active');
                }
            });
        }
    }

    function getScrollSpyOffset() {
        $navLinks.each(function (index, element) {
            var $target = $(this).data('target');
            var $element = $('section[data-target="' + $target + '"]');
            $scrollSpyOffset[$target] = [];
            $scrollSpyOffset[$target]['offset'] = $element.offset().top;
            $scrollSpyOffset[$target]['height'] = $element.offset().top + $element.innerHeight();
        });
    }
});

function setRgpdCookie() {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = 'RgpdCookie=1;' + expires + ';path=/';
    document.getElementById('header--rgpd').style.display = 'none';
    document.body.classList.add('rgpd');
}

function getRgpdCookie() {
    var name = 'RgpdCookie=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i <ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

function checkRgpdCookie() {
    var RgpdCookie = getRgpdCookie('RgpdCookie');

    if (RgpdCookie != '') {
        document.body.classList.add('rgpd');
        return true;
    } else {
        return false;
    }
}

if (!checkRgpdCookie()) {
    document.getElementById('header--rgpd').style.display = '';
}

WebFontConfig = {
    google: {families: ['Poppins:500,700', 'Raleway:400,500,800,900', 'Work+Sans:200,400,600,700']}
};
(function() {
    var wf = document.createElement('script');
    wf.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();